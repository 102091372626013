import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Button from '../common/Button';
import PreviewCompatibleImage from '../common/PreviewCompatibleImage';

const Media = styled.div`
  width: 100%;
  position: relative;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  margin-bottom: 0.5rem;
  @media (min-width: 600px) {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 0.75rem;
  }
`;

const Desc = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  /* color: #666666; */
  margin-bottom: 1em;
  /* @media (min-width: 600px) {
    font-size: 20px;
    line-height: 32px;
  } */
`;

const Meta = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const pageMargins = css`
  padding: 0 20px;
  @media (min-width: 600px) {
    margin: 0 ${5.55555 * 1}%;
  }
  @media (min-width: 1200px) {
    margin: 0 ${5.55555 * 2}%;
  }
  @media (min-width: 1800px) {
    margin: 0 ${5.55555 * 3}%;
  }
  @media (min-width: 2400px) {
    margin: 0 ${5.55555 * 4}%;
  }
`;

const Wrapper = styled.div`
  /* ${pageMargins}; */
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  @media (min-width: 600px) {
    text-align: left;
    /* padding-top: 7rem !important;
    padding-bottom: 7rem !important; */
    ${Meta} {
      order: 1;
      flex: 1;
    }
    ${Media} {
      order: 2;
      flex: 0 0 auto;
      display: block;
      width: 45%;
    }
    ${props =>
      props.orientation === 'left' &&
      css`
        /* Text on left */
        ${Meta} {
          padding-right: 10%;
        }
      `};
    ${props =>
      props.orientation === 'right' &&
      css`
        /* Text on right */
        flex-direction: row-reverse;
        ${Meta} {
          padding-right: 0;
          padding-left: 10%;
        }
      `};
    ${props =>
      props.orientation === 'center' &&
      css`
        /* Image in center */
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
        text-align: center;
        flex-direction: column;
        ${Meta} {
          order: 1;
          max-width: 600px;
          margin-bottom: 2rem;
        }
        ${Media} {
          order: 2;
          width: 100%;
        }
      `};
  }
`;

const Separator = styled.hr`
  margin: 0;
  background-color: ${p => p.theme.separatorColor};
`;

const ServiceInfo = ({ info }) => (
  <>
    <Separator />
    {info.map(el => (
      <div key={el.title}>
        <Wrapper orientation={el.orientation}>
          <Meta>
            {el.title && <Title>{el.title}</Title>}
            {el.description && <Desc>{el.description}</Desc>}
            <Button className="button" to={`/leistungen#${el.route}`}>
              Mehr erfahren
            </Button>
          </Meta>

          <Media>
            {el.image && <PreviewCompatibleImage imageInfo={el.image} />}
          </Media>
        </Wrapper>
        <Separator />
      </div>
    ))}
  </>
);

export default ServiceInfo;

ServiceInfo.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      orientation: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};
