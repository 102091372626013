import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Banner } from '../components/common';
import { ServiceInfo, Slider, Text } from '../components/home';

export const HomePageTemplate = ({
  slide,
  heading,
  image,
  info,
  fullImage,
  invitation,
}) => (
  <>
    <Slider slide={slide} />
    <section className="section">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <Text text={heading} to="/uber-uns">
                  Mehr erfahren
                </Text>
                <Banner image={image} />
                <ServiceInfo info={info} />
                <Banner image={fullImage} />
                <Text endText text={invitation} to="/kontakt">
                  Kontakt
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

HomePageTemplate.propTypes = {
  slide: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      orientation: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  invitation: PropTypes.string.isRequired,
};

const HomePage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <Layout>
    <HomePageTemplate
      slide={frontmatter.slide}
      heading={frontmatter.heading}
      image={frontmatter.image}
      info={frontmatter.info}
      fullImage={frontmatter.full_image}
      invitation={frontmatter.invitation}
    />
  </Layout>
);

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slide {
          image {
            img: childImageSharp {
              id
              fluid(maxWidth: 1366, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          heading
          description
        }
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1366, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        info {
          image {
            alt
            img {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          orientation
          route
          title
          description
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 1366, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        invitation
      }
    }
  }
`;
