import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import 'slick-carousel/slick/slick.css';

const Container = styled.div`
  padding-top: ${p => p.theme.navbarHeight};
`;

const Title = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 1em 1em 1.5em;
  z-index: 2;
  h2 {
    /* text-transform: lowercase; */
    margin-top: 0;
    font-size: 3.5em;
    line-height: 1;
    padding-bottom: 1rem;
    font-family: ${p => p.theme.headerFontFamily};
  }
  p {
    font-size: 1.25em;
  }
  ${p =>
    p.theme.minWidth.tablet(css`
      h2 {
        margin-top: 0;
        font-size: 6em;
      }
      p {
        font-size: 1.5em;
      }
    `)}
`;

const TitleContent = styled.div`
  text-align: left;
  transform: translate(0%, 10%);
  ${p =>
    p.theme.minWidth.tablet(css`
      transform: translate(0%, 15%);
    `)}
  ${p =>
    p.theme.minWidth.desktop(css`
      transform: translate(0%, 20%);
    `)}
`;

const Slick = ({ slide }) => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 600,
    fade: true,
    arrows: false
  };
  return (
    <Container>
      <Slider {...settings}>
        {slide.map(el => (
          <div key={el.image.img.id}>
            <Img fluid={el.image.img.fluid} />
            <Title>
              <TitleContent>
                <div className="section">
                  <div className="container">
                    <h2>{el.heading}</h2>
                    <p>{el.description}</p>
                  </div>
                </div>
              </TitleContent>
            </Title>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default Slick;

Slick.propTypes = {
  slide: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired
};
