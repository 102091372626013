import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Button from '../common/Button';

const StartText = styled.p`
  margin-top: -1em;
  margin-bottom: 1em !important;
  font-size: 1.25em !important;
  ${p =>
    p.theme.minWidth.tablet(css`
      margin-top: 0;
      margin-bottom: 1em !important;
      font-size: 1.5em !important;
    `)}
`;

const EndText = styled.p`
  margin-top: 4em;
  font-size: 1.25em !important;
  ${p =>
    p.theme.minWidth.tablet(css`
      font-size: 1.5em !important;
    `)}
`;

const Text = ({ endText, text, to, children }) => (
  <>
    {endText ? <EndText>{text}</EndText> : <StartText>{text}</StartText>}
    <Button className="button is-medium" to={to}>
      {children}
    </Button>
  </>
);

export default Text;
